import { useState } from "react";
import useAccessToken from "../../auth/useAccessToken";
import ApiError, { buildApiError, isApiError } from "./apiError";
import { GigApiResponse } from "./apiResponse";
import buildApiUrl from "./buildApiUrl";
import { errorToast } from "../../toast";

const buildHeaders = (token?: string | null, contentType?: string) => {
    const headers = new Headers();

    if (token) headers.set("Authorization", `Bearer ${token}`);
    if (contentType) headers.set("Content-Type", contentType);

    return headers;
};

export type GigApiFetcherResponse<TRes> = {
    success: true
    content: TRes
} | {
    success: false
    error: ApiError
}

export type GigApiFetcherParams<TReq> = {
    url: string,
    method?: HttpMethod
    body?: TReq
    token?: string | null
    allow400?: boolean
    customHeaders?: { [key: string]: string }
}

const gigApiFetcher = async <TReq, TRes>({
    url,
    method = "GET", 
    body,
    token,
    customHeaders = {}
}: GigApiFetcherParams<TReq>) => {
    
    const path = buildApiUrl(url);

    // Build the headers using the Headers constructor
    const headers = buildHeaders(token, body ? "application/json" : undefined);

    // Add custom headers
    Object.entries(customHeaders).forEach(([key, value]) => {
        if (value) headers.append(key, value);
    });

    const response = await fetch(path, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
        const error = await buildApiError(method, url, response);        

        // temp fix for routing forbidden or not found requests
        // initially just tried to redirect to login but then authenticated users will be redirected from 404/3 to login     
        if(error.statusCode === 404) {
            window.location.href = "/404";
        }
        if(error.statusCode === 403 && !window.location.pathname.startsWith("/403")) {
            window.location.href = "/403";
        }
        if(error.statusCode === 400) {
            error.userMessages.forEach(message => errorToast(message));
        }
        throw error;
    }

    const responseContent: GigApiResponse<TRes> = await response.json();

    return responseContent.content;
};

const normalizedGigApiFetcher = async <TReq, TRes>({
    url, 
    method = "GET", 
    body, 
    token
}: GigApiFetcherParams<TReq>): Promise<GigApiFetcherResponse<TRes>> => {

    try {
        const response = await gigApiFetcher<TReq, TRes>({url, method, body, token});

        return {
            success: true,
            content: response,
        };
    }
    catch (error) {
        if (isApiError(error)) {
            return {
                success: false,
                error,
            };
        }

        throw error;
    }
    
};

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export const useAuthenticatedGigApiFetcher = <TReq, TRes, TArgs>(
    method: HttpMethod,
    requestBuilder: (arg: TArgs) => { url: string, body?: TReq }):
    [(arg: TArgs) => Promise<GigApiFetcherResponse<TRes>>, boolean] => {
    const token = useAccessToken();
    const [isFetching, setIsFetching] = useState(false);

    const fetcher = async (args: TArgs) => {
        if (!token) throw new Error("Attempted to make authenticated request without access token.");

        const { url, body } = requestBuilder(args);

        setIsFetching(true);
        const response = await normalizedGigApiFetcher<TReq, TRes>({url, method, body, token});
        setIsFetching(false);

        return response;
    };

    return [fetcher, isFetching];
};

export default gigApiFetcher;
