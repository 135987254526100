import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGig } from "../api/gig";
import Button from "../components/Button";
import CancelGigDialog from "../components/CancelGigDialog";
import Card from "../components/Card";
import DialogBox, { useDialogBoxState } from "../components/DialogBox";
import { GigFormValues, useGigForm, GigFormFields } from "../components/GigForm";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { gigStatuses } from "../models/app/gig";
import { useGigId } from "../Routes";
import FileManager from "../components/FileManager";
import { convertFileToDataUrl } from "../utils/convertFileToDataUrl";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useAppPaths } from "../Routes";
import { dialogWidths } from "../components/DialogBox/DialogBox";
import { successToast } from "../toast";
import Loader from "../components/Loader";
import { getLocalEquivalentDateTimeString, convertUTCToLocalTime } from "../utils/dateFormatters";

const GigEditPage = () => {
    const [gigLoaded, setGigLoaded] = useState(false);
    const appPaths = useAppPaths();
    const gigId = useGigId();
    const confirmCancelDialogState = useDialogBoxState();
    const navigate = useNavigate();
    const cancelGigDialogState = useDialogBoxState();
    const holdGigDialogState = useDialogBoxState();
    const { isUpdatingGig, isAddingFileToGig, isDeletingFileFromGig, updateGig, addFileToGig, deleteFileFromGig, gig, putGigOnHold, isPuttingGigOnHold } = useGig(gigId);
    const { gigTerminology } = useOrganizationContext();

    const methods = useGigForm(
        {
            ...gig,
            title: gig?.title ? gig.title : "",
            hasClientHistory: gig?.hasClientHistory ? "true" : "false",
            industryId: gig?.industryId ? gig.industryId : "",
            opportunityLink: gig?.opportunityLink ? gig.opportunityLink : "",
            skills: gig?.skills ? gig.skills : [],
            gigTaggedUsers: gig?.gigTaggedUsers ? gig.gigTaggedUsers : [],
            isAudienceExecutive: gig?.isAudienceExecutive ? "true" : "false",
            languageId: gig?.languageId ? gig?.languageId : "",
            delegateInformation: gig?.delegateInformation ? gig?.delegateInformation : "",
            isDesignFeeTypeRequired: gig?.isDesignFeeTypeRequired ? "true" : "false",
            isConsultancyFeeTypeRequired: gig?.isConsultancyFeeTypeRequired ? "true" : "false",
            isPrepAndEvalFeeTypeRequired: gig?.isPrepAndEvalFeeTypeRequired ? "true" : "false",
            designFeeTypeHours: gig?.designFeeTypeHours ?? 0,
            consultancyFeeTypeHours: gig?.consultancyFeeTypeHours ?? 0,
            prepAndEvalFeeTypeHours: gig?.prepAndEvalFeeTypeHours ?? 0,
            expectedNumberParticipants: gig?.expectedNumberParticipants ?? 0,
            description: gig?.description ? gig.description : "",
            startDate: gig?.startDate ? new Date(convertUTCToLocalTime(gig.startDate, gig.ianaId).localDateTimeString) : null,
            ianaId: gig?.ianaId,
            expectedDurationId: gig?.expectedDurationId ? gig.expectedDurationId : -1,
            locationId: gig?.location?.countryId ? gig.location.countryId : "",
            localityId: gig?.location?.locality.localityId ? gig?.location?.locality.localityId : gig?.localityId || "",
            deliveryTypeId: gig?.deliveryTypeId ?? -1,
            gigStatusId: gig?.gigStatusId ? gig.gigStatusId : 1,
            address: gig?.address ? gig?.address : "",
        }
    );

    const { reset } = methods;

    useEffect(() => {
        if (gigLoaded) return;
        
        reset({
            ...gig,
            startDate: gig?.startDate ? new Date(convertUTCToLocalTime(gig.startDate, gig.ianaId).localDateTimeString) : null,
            hasClientHistory: gig?.hasClientHistory ? "true" : "false",
            isDesignFeeTypeRequired: gig?.isDesignFeeTypeRequired ? "true" : "false",
            isConsultancyFeeTypeRequired: gig?.isConsultancyFeeTypeRequired ? "true" : "false",
            isPrepAndEvalFeeTypeRequired: gig?.isPrepAndEvalFeeTypeRequired ? "true" : "false",
            isAudienceExecutive: gig?.isAudienceExecutive ? "true" : "false",
            locationId: gig?.location?.countryId,
            localityId: gig?.location?.locality.localityId,
            industryId: gig?.industry?.id,
            languageId: gig?.language?.id
        });

        if (gig) {
            setGigLoaded(true);
        }
    }, [gig, gigLoaded]);

    if (!gig) return <Loader />;

    const handleFileUpload = async (file: File) => {
        const dataUrl = await convertFileToDataUrl(file);
        await addFileToGig({ fileDataUri: dataUrl });
    };

    const handleFileDelete = async (fileId: string) => {
        await deleteFileFromGig({ fileId });
    };

    const handleSubmit = async (value: GigFormValues, postAfterSaving: boolean) => {
        if (value.startDate === null) throw ("Expected start date not to be null.");
        return await updateGig({
            ...value,
            startDate: getLocalEquivalentDateTimeString(value.startDate, value.ianaId ?? ""),
            skillIds: value.skills.map(_ => _.id),
            gigTaggedUserIds: value.gigTaggedUsers ? value.gigTaggedUsers.map(_ => _.userId) : [],
            postAfterSaving: postAfterSaving,
            hasClientHistory: JSON.parse(value.hasClientHistory),
            isDesignFeeTypeRequired: JSON.parse(value.isDesignFeeTypeRequired),
            isConsultancyFeeTypeRequired: JSON.parse(value.isConsultancyFeeTypeRequired),
            isPrepAndEvalFeeTypeRequired: JSON.parse(value.isPrepAndEvalFeeTypeRequired),
            isAudienceExecutive: JSON.parse(value.isAudienceExecutive),
            locationId: value.locationId,
            localityId: value.localityId ? value.localityId : gig.localityId,
            description: value.description || null,
            delegateInformation: value.delegateInformation || null,
            opportunityLink: value.opportunityLink || null,
            notes: value.notes || null,
            address: value.address || null
        });
    };

    const handleSaveAndPost = async (value: GigFormValues) => {
        const response = await handleSubmit(value, true);

        if (response.success) {
            navigate(appPaths.gigs.matches(gig.id));
            successToast(`${gigTerminology} updated successfully.`);
        }
    };

    const handleSaveAsDraft = methods.handleSubmit(async data => {
        const response = await handleSubmit(data, false);

        if (response.success) {
            navigate(appPaths.gigs.myGigs("draft"));
        }
    });

    const handleDiscardChanges = () => {
        navigate(gig.gigStatusId === gigStatuses.posted  ? 
            appPaths.gigs.myGigs("posted") : gig.gigStatusId === gigStatuses.hired ? 
                appPaths.gigs.myGigs("hired") : appPaths.gigs.myGigs("draft"));
    };

    const handlePutGigOnHold = async () => {
        const response = await putGigOnHold();

        if (response.success) {
            holdGigDialogState.close();
            navigate(appPaths.gigs.myGigs("draft"));
            successToast(`${gigTerminology} put on hold successfully.`);
            return;
        }
    };

    const handleConfirmChanges = methods.handleSubmit(async data => {
        const response = await handleSubmit(data, false);

        if (response.success) {
            navigate(appPaths.gigs.myGigs("hired"));
        }
    });

    return (
        <PageContent className="sm:pt-8 pt-4">
            <Card>
                <Typography variant="display-medium" component="h1" gutterBottom>Edit {gigTerminology.toLowerCase()}</Typography>
                <form onSubmit={methods.handleSubmit(handleSaveAndPost)} noValidate>
                    <GigFormFields
                        methods={methods}
                        hideSkillsDropdown={false}
                        initialValues={{
                            ...gig,
                            startDate: gig?.startDate ? new Date(convertUTCToLocalTime(gig.startDate, gig.ianaId).localDateTimeString) : null,
                            hasClientHistory: gig.hasClientHistory ? "true" : "false",
                            isAudienceExecutive: gig.isAudienceExecutive ? "true" : "false",
                            isDesignFeeTypeRequired: gig?.isDesignFeeTypeRequired ? "true" : "false",
                            isConsultancyFeeTypeRequired: gig?.isConsultancyFeeTypeRequired ? "true" : "false",
                            isPrepAndEvalFeeTypeRequired: gig?.isPrepAndEvalFeeTypeRequired ? "true" : "false",
                            locationId: gig.location?.countryId ? gig.location.countryId : "",
                            localityId: gig.location?.locality.localityId ? gig.location.locality.localityId : "",
                            languageId: gig.languageId ? gig.languageId : "",
                        }}
                    />

                    {
                        gig.gigStatusId !== gigStatuses.draft &&
                        <div className={"mb-0 mx-0 mt-8 space-y-6"}>
                            {isAddingFileToGig || isDeletingFileFromGig ? (<p>Loading...</p>) :
                                <FileManager
                                    label={"Relevant files"}
                                    inputId="relevant-files-upload-button"
                                    existingFiles={gig.files}
                                    onFileDrop={handleFileUpload}
                                    onFileDelete={handleFileDelete}
                                    // tooltip={clientEditGigTooltips.gigFiles(gigTerminology)}
                                    maxFileSizeKb={5000}
                                />
                            }
                        </div>
                    }

                    <div className={clsx(
                        "mb-0 mx-0 mt-8 space-y-4",
                        "md:flex md:flex-row-reverse md:justify-end md:space-y-0"
                    )}>
                        {gig.gigStatusId === gigStatuses.pending || gig.acceptedInvite ? (
                            <Button type="button" onClick={handleConfirmChanges} loading={isUpdatingGig} className="w-full md:w-auto">Confirm changes</Button>
                        ) : (
                            <>
                                <Button type="submit" loading={isUpdatingGig} className="w-full md:w-auto">{gig.gigStatusId === gigStatuses.draft ? `Post ${gigTerminology.toLowerCase()}` : `Update ${gigTerminology.toLowerCase()}`}</Button>
                                {gig.gigStatusId === gigStatuses.draft && (
                                    <Button type="button" onClick={handleSaveAsDraft} loading={isUpdatingGig} className="w-full md:w-auto md:mr-4" variant="secondary">Save draft</Button>
                                )}
                                {gig.gigStatusId !== gigStatuses.draft && <Button type="button" onClick={holdGigDialogState.open} className="w-full md:w-auto md:mr-4" variant="secondary" disabled={isPuttingGigOnHold}>{`Put ${gigTerminology.toLowerCase()} on hold`}</Button> }
                            </>
                        )}
                        <Button type="button" onClick={cancelGigDialogState.open} className="w-full md:w-auto md:mr-4" variant="tertiary" disabled={isUpdatingGig}>Cancel {gigTerminology.toLowerCase()}</Button>
                        <Button type="button" disabled={isUpdatingGig || !methods.formState.isDirty} className="w-full md:w-auto md:mr-auto" variant="tertiary" onClick={confirmCancelDialogState.open}>Discard changes</Button>
                    </div>
                </form>
                <DialogBox
                    {...confirmCancelDialogState}
                    title="Discard changes?"
                >
                    <div className="space-y-4">
                        <Button className="block mx-auto w-full" type="button" onClick={handleDiscardChanges}>Discard changes</Button>
                        <Button className="block mx-auto w-full" type="button" variant="secondary" onClick={confirmCancelDialogState.close}>Back to edit {gigTerminology.toLowerCase()}</Button>
                        {gig.gigStatusId === gigStatuses.draft && (
                            <Button className="block mx-auto w-full" type="button" onClick={handleSaveAsDraft} variant="tertiary">Save as draft</Button>
                        )}
                    </div>
                </DialogBox>
                <CancelGigDialog
                    gigId={gig.id}
                    isOpen={cancelGigDialogState.isOpen}
                    onClose={cancelGigDialogState.close}
                    requireReason={gig.gigStatusId === gigStatuses.posted || gig.gigStatusId === gigStatuses.hired}
                />
                <DialogBox
                    {...holdGigDialogState}
                    title={`Putting this ${gigTerminology.toLowerCase()} on hold will move it into your 'Drafts'. Continue?`}
                    maxWidth={dialogWidths["small"]}
                >
                    <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                        <Button className="w-full mt-4 sm:mt-0" type="button" variant="secondary" onClick={holdGigDialogState.close} disabled={isPuttingGigOnHold}>Back</Button>
                        <Button className="w-full" type="button" onClick={handlePutGigOnHold} variant="primary" loading={isPuttingGigOnHold} disabled={isPuttingGigOnHold}>Put on hold</Button>
                    </div>
                </DialogBox>
            </Card>
        </PageContent>
    );
};

export default GigEditPage;
