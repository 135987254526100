import { FieldError } from "react-hook-form";

import Chip, { ChipContainer } from "./Chip";
import { FormSearchDropdownOption } from "./FormSearchDropdown/FormSearchDropdown";
import FormInputLabel from "./FormInputLabel";
import TaggedSearchDropdown from "./TaggedSearchDropdown";

export type GigTaggedTypeInputProps = {
    addTagged: (gigTaggedUser: { id?: string, userId: string, userName: string }) => void
    removeTagged: (index: number) => void
    gigTaggedUsers: { userId: string, userName: string }[]
    error?: any,
    showError?: boolean
    hideDropdown?: boolean
    tooltip?: string
    isMulti?: boolean
}

const GigTaggedTypeInput = ({
    addTagged,
    removeTagged,
    gigTaggedUsers,
    error,
    showError,
    hideDropdown,
    tooltip,
    isMulti
}: GigTaggedTypeInputProps) => {
    const handleTagged = (_: string, option: FormSearchDropdownOption | FormSearchDropdownOption[] | null) => {
        if (option === null) return;
        const optionsArray = Array.isArray(option) ? option : [option];
        optionsArray.forEach(item => {
            addTagged({
                userId: item.value,
                userName: item.label
            });

            if (!gigTaggedUsers) {
                gigTaggedUsers = [];
            }
            if (gigTaggedUsers.length <= 1) {
                gigTaggedUsers.push({
                    userId: item.value,
                    userName: item.label
                });
            }
        });
    };

    const handleRemoveTagged = (index: number) => {
        removeTagged(index);
    };

    const fieldError: FieldError | undefined = (error)?.message ? error as FieldError : undefined;
    
    if (!gigTaggedUsers) return <>"Loading..."</>;

    return (
        <div>
            {!hideDropdown && (
                <TaggedSearchDropdown
                    value={""}
                    isMulti={isMulti}
                    onChange={handleTagged}
                    disabled={!gigTaggedUsers}
                    disabledTaggedIds={gigTaggedUsers.map(_ => _.userId)}
                    error={showError ? fieldError : undefined}
                    tooltip={tooltip}
                />
            )}
            {hideDropdown && (
                <FormInputLabel required className="mt-6">User</FormInputLabel>
            )}
            <ChipContainer className={hideDropdown ? undefined : "pt-2"}>
                {gigTaggedUsers.map((taggedData, index) => (
                    <Chip
                        key={`tagged-${taggedData.userId}`}
                        label={taggedData.userName}
                        onDelete={() => handleRemoveTagged(index)} />
                ))}
            </ChipContainer>
        </div>
    );
};

export default GigTaggedTypeInput;