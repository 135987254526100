import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import GigSkillsInput from "../GigProductTypeInput";
import LanguageDropdown from "../LanguageDropdown";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import FormRadioGroup from "../FormRadioGroup";
import { radioOptions } from "../../models/app/gig";
import FormTextAreaInput from "../FormTextAreaInput";

export type CreateGigFormStep2Fields = Pick<GigFormValues, "skills" | "languageId" | "isAudienceExecutive" | "delegateInformation">;

export type CreateGigFormStep2Props = {
    onSubmit: (value: CreateGigFormStep2Fields) => void
    onBackClick: (value: CreateGigFormStep2Fields) => void
    defaultValues: CreateGigFormStep2Fields
}

const CreateGigFormStep2 = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep2Props) => {
    const { gigTerminology } = useOrganizationContext();

    const validationSchema = yup.object({
        skills: buildGigFormValidationSchemaFields.skills,
        languageId: buildGigFormValidationSchemaFields.languageId,
        isAudienceExecutive: buildGigFormValidationSchemaFields.isAudienceExecutive,
        delegateInformation: buildGigFormValidationSchemaFields.delegateInformation
    });

    const methods = useForm<CreateGigFormStep2Fields>({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const { append, remove } = useFieldArray<CreateGigFormStep2Fields, "skills">({
        control: methods.control,
        name: "skills",
        keyName: "id",
    });

    const { skills } = methods.watch();

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="Event Information"
                description="Please enter all the required information the client has specified as requirements for their event."
                content={
                    <span className="space-y-6">
                        <GigSkillsInput
                            addSkill={append}
                            removeSkill={remove}
                            skills={skills}
                            error={methods.formState.errors.skills}
                            showError={methods.formState.submitCount > 0}
                        />
                        <Controller
                            name="languageId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <LanguageDropdown
                                    required
                                    label={`Language this ${gigTerminology.toLowerCase()} is to be delivered in`}
                                    error={methods.formState.errors.languageId}
                                    onChange={onChange}
                                    value={value || ""}
                                />
                            )}
                        />
                        <Controller
                            name="isAudienceExecutive"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormRadioGroup
                                    required
                                    label="Is the audience Senior Executive/C-Suite level?"
                                    options={radioOptions}
                                    error={methods.formState.errors.isAudienceExecutive}
                                    defaultValue={value}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        <FormTextAreaInput
                            id="create-gig-form-delegate-information"
                            label="Delegate information"
                            placeholder="Please provide any additional information which would help us to source the best fit AC for your client's event"
                            register={methods.register("delegateInformation")}
                            error={methods.formState.errors.delegateInformation}
                            defaultValue={defaultValues.delegateInformation || undefined}
                            rows={4}
                            isResizeable
                        />
                    </span>
                }
                iconName="ClipboardText"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep2;