import gigApiFetcher from "./common/fetching";
import { LocalityDto, LocationDto } from "../models/app/location";
import { FormDropdownOption } from "../components/FormDropdown";
import { GigApiPaginatedData } from "./common/apiResponse";

export const fetchLocations = async (query = "", accessToken: string | null): Promise<LocationDto[]> => {
    const response = await gigApiFetcher<never, LocationDto[]>({
        url: `api/locations?searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchLocationOptions = async (query: string, accessToken: string | null): Promise<FormDropdownOption<string>[]> => {
    const locationsResponse = await fetchLocations(query, accessToken);

    return locationsResponse
        .map(location => ({
            value: location.id,
            label: location.country,
        }));
};

export const fetchLocalities = async (locationId: string, accessToken: string | null, searchTerm: string, pageIndex: number, pageSize: number) => {
    const queryParams = new URLSearchParams({
        searchTerm: searchTerm, 
        locationId: locationId, 
        pageIndex: pageIndex.toString(), 
        pageSize: pageSize.toString()
    });
    const response = await gigApiFetcher<never, GigApiPaginatedData<LocalityDto>>({
        url: `api/locations/localities?${queryParams.toString()}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });
    
    return response;
};

export const fetchLocalityById = async (localityId: string, accessToken: string | null) => {
    if (!localityId || !accessToken) return null;
    
    try {
        const response = await gigApiFetcher<never, LocalityDto>({
            url: `api/locations/localities/${localityId}`,
            method: "GET",
            body: undefined,
            token: accessToken,
        });
        
        return response;
    } catch (error) {
        console.error("Error fetching locality:", error);
        return null;
    }
};