import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import FormTextInput from "../FormTextInput";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm/GigFormValues";
import { formClassNames } from "./classNames";
import LinkButton from "../LinkButton";
import { useAppPaths } from "../../Routes";
import FormRadioGroup from "../FormRadioGroup";
import { radioOptions } from "../../models/app/gig";
import IndustrialExperienceDropdown from "../IndustrialExperienceDropdown";

export type CreateGigFormStep1Fields = Pick<GigFormValues, "title" | "hasClientHistory" | "industryId" | "opportunityLink">;

export type CreateGigFormStep1Props = {
    onSubmit: (value: CreateGigFormStep1Fields) => void
    defaultValues: CreateGigFormStep1Fields
}

const CreateGigFormStep1 = ({
    onSubmit,
    defaultValues,
}: CreateGigFormStep1Props) => {
    const appPaths = useAppPaths();

    const validationSchema: yup.SchemaOf<CreateGigFormStep1Fields> = yup.object({
        title: buildGigFormValidationSchemaFields.title,
        hasClientHistory: buildGigFormValidationSchemaFields.hasClientHistory,
        industryId: buildGigFormValidationSchemaFields.industryId,
        opportunityLink: buildGigFormValidationSchemaFields.opportunityLink
    });

    const methods = useForm<CreateGigFormStep1Fields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="Client Information"
                description="Please enter the required information on the client you would like us to source an AC for."
                content={
                    <span className="space-y-6">
                        <FormTextInput
                            required
                            id="create-gig-form-client-name"
                            label="Name of the client"
                            placeholder="Enter name of the client"
                            error={methods.formState.errors.title}
                            defaultValue={defaultValues.title}
                            {...methods.register("title")}
                        />
                        <Controller
                            name="hasClientHistory"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormRadioGroup
                                    required
                                    label="Have we worked with this client before?"
                                    options={radioOptions}
                                    error={methods.formState.errors.hasClientHistory}
                                    defaultValue={value}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        <Controller
                            name="industryId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <IndustrialExperienceDropdown
                                    required
                                    error={methods.formState.errors.industryId}
                                    onChange={onChange}
                                    value={value || ""}
                                />
                            )}
                        />
                        <FormTextInput
                            id="create-gig-form-opportunity-link"
                            label="Enter opportunity link"
                            placeholder="Please provide the URL from D365"
                            error={methods.formState.errors.opportunityLink}
                            defaultValue={defaultValues.opportunityLink || undefined}
                            {...methods.register("opportunityLink")}
                        />
                    </span>
                }
                iconName="User"
                buttons={
                    <>
                        <LinkButton to={appPaths.gigs.createIntro} variant="secondary">Back</LinkButton>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep1;