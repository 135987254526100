import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import useAccessToken from "../auth/useAccessToken";
import { FormSearchDropdown } from "./FormSearchDropdown";
import { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";
import { fetchTaggedCount, fetchTaggedUsers } from "../api/tagged-user";

export type GigTaggedUserSearchDropdownProps = {
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    disabled?: boolean
    required?: boolean
    error?: FieldError
    disabledTaggedIds?: string[],
    tooltip?: string
    isMulti?: boolean
}

const TaggedSearchDropdown = ({
    value,
    onChange,
    disabled,
    required,
    error,
    disabledTaggedIds = [],
    tooltip,
    isMulti
}: GigTaggedUserSearchDropdownProps) => {
    const accessToken = useAccessToken();
    const [taggedOptions, setTaggedOptions] = useState<FormSearchDropdownOption[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTagged = async (searchQuery: string, pageIndex?: number) => {
        setIsLoading(true);

        try {
            const taggedResponse = await fetchTaggedUsers(searchQuery, accessToken, pageIndex);
            return taggedResponse.map(tagged => ({
                value: tagged.userId,
                label: tagged.userName,
            }));
        } 
        finally {
            setIsLoading(false);
        }
    };

    const loadTaggedOptions = (inputValue: string, callback: LoadOptionsCallback) => {
        fetchTagged(inputValue)
            .then(options => callback(options));
    };

    useEffect(() => {
        if (!accessToken || taggedOptions.length >= totalCount) return;

        const loadFetchedTagged = async () => {
            const fetchedSkillOptions = await fetchTagged("", pageIndex);
            setTaggedOptions(existingSkills => [...existingSkills, ...fetchedSkillOptions]);
        };

        loadFetchedTagged();
    }, [accessToken, pageIndex, totalCount]);

    useEffect(() => {
        if (!accessToken) return;
        fetchTaggedCount(accessToken).then(count => setTotalCount(count));

    }, [accessToken]);

    const fetchMoreTagged = () => {
        if (taggedOptions.length === totalCount) return;
        setPageIndex(prev => prev + 1);
    };

    const debouncedLoadTaggedOptions = useDebouncedCallback(loadTaggedOptions, 700);

    return (
        <FormSearchDropdown
            label="Would you like to tag any other users in this request?"
            tooltip={tooltip}
            value={value}
            isMulti={isMulti}
            loadOptions={debouncedLoadTaggedOptions}
            onChange={onChange}
            isClearable
            defaultOptions={taggedOptions}
            disabled={disabled}
            required={required}
            error={error}
            disabledOptionIds={disabledTaggedIds}
            fetchMore={fetchMoreTagged}
            loading={isLoading}
        />
    );
};

export default TaggedSearchDropdown;