import { useCallback, useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { FormSearchDropdown } from "./FormSearchDropdown";
import { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";
import { fetchLocations } from "../api/locations";

type LocationSearchDropdownProps = {
    value: string
    onChange: (locationId: string, option: FormSearchDropdownOption | null) => void
    error?: FieldError
    label?: string
    required?: boolean
    disabled?: boolean
    tooltip?: string
    accessToken: string | null
    isClearable?: boolean
}

export const LocationSearchDropdown = ({
    value,
    onChange,
    error,
    label = "Country",
    required,
    disabled,
    tooltip,
    accessToken,
    isClearable
}: LocationSearchDropdownProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState<FormSearchDropdownOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<FormSearchDropdownOption | null>(null);

    // Load initial options and selected value on mount
    useEffect(() => {
        const loadInitialData = async () => {
            if (!accessToken) return;
            
            try {
                setIsLoading(true);
                const locations = await fetchLocations("", accessToken);
                const options = locations.map(location => ({
                    value: location.id,
                    label: location.country
                }));
                setDefaultOptions(options);

                // If we have a pre-selected value, find its option
                if (value) {
                    const selected = options.find(opt => opt.value === value);
                    if (selected) {
                        setSelectedOption(selected);
                    }
                }
            } catch (error) {
                console.error("Error loading initial locations:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialData();
    }, [accessToken, value]);

    const loadLocationOptions = useCallback(async (
        inputValue: string,
        callback: LoadOptionsCallback
    ) => {
        if (!accessToken) {
            callback([]);
            return;
        }

        try {
            setIsLoading(true);
            const locations = await fetchLocations(inputValue, accessToken);
            const options = locations.map(location => ({
                value: location.id,
                label: location.country
            }));
            callback(options);
        } catch (error) {
            console.error("Error fetching locations:", error);
            callback([]);
        } finally {
            setIsLoading(false);
        }
    }, [accessToken]);

    return (
        <FormSearchDropdown
            label={label}
            value={value}
            onChange={onChange}
            error={error}
            required={required}
            disabled={disabled}
            tooltip={tooltip}
            loadOptions={loadLocationOptions}
            isClearable={isClearable}
            loading={isLoading}
            defaultOptions={defaultOptions}
            selectedOption={selectedOption}
        />
    );
};