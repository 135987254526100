import { useGig } from "../../api/gig";
import Card, { CardDataField } from "../../components/Card";
import CardDataFieldLabel from "../../components/Card/CardDataFieldLabel";
import TitleUnderline from "../../components/TitleUnderline";
import Typography from "../../components/Typography";
import { deliveryTypeOptions } from "../../models/app/gig";
import { useGigId } from "../../Routes";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import ReportButton from "../../components/Reports";
import Loader from "../../components/Loader";
import WorksomeIcon from "../../components/WorksomeIcon";
import useCurrentUser from "../../auth/useCurrentUser";
import TalentInviteActions from "./TalentInviteActions";
import { hasClaim } from "../../auth/hasClaim";
import { UserRole } from "../../models/app/userMe";
import AuditDataList from "../../components/AuditData/AuditDataList";
import { formatLocationDateTimeToString } from "../../utils/dateFormatters";
import Tooltip from "../../components/Tooltip";
import DateDisplayLocale from "../../components/DateDisplayLocale";

const FindAGigDetailsPage = () => {
    const gigId = useGigId();
    const { gig, isLoading } = useGig(gigId);
    const { organizationConfig } = useOrganizationContext();
    const { clientId, claims } = useCurrentUser();

    const isTalent = hasClaim(UserRole.AC, claims);

    if (!gig || isLoading) return <Loader />;

    const hasCustomRequirements = gig.isDesignFeeTypeRequired || gig.isConsultancyFeeTypeRequired || gig.isPrepAndEvalFeeTypeRequired;

    return (
        <div className="m-4 lg:m-8 break-words">
            <div className="mx-auto space-y-8 max-w-6xl">
                <Card key={gig.id} className="space-y-6 md:flex">
                    <div className="w-full md:mr-8 overflow-hidden relative">
                        {gig.isWorksome && (
                            <WorksomeIcon className="absolute right-0 top-0 transform translate-x-0 translate-y-0" tooltipText={`Payments and contractual details for this ${organizationConfig.gigTerminology.toLowerCase()} will be handled via Worksome`} />
                        )}
                        <div className="space-y-6">
                            <div className="space-y-4">
                                <Typography variant="display-medium" component="h1">{gig?.title}</Typography>
                                <Typography variant="title-medium" component="h3">{gig.skills[0].name}</Typography>
                                <Typography variant="title-small" component="h3">{`${gig.location?.locality.locality}${gig.location?.locality.region ? ` (${gig.location?.locality.region})` : ""}, ${gig.location?.country}`}</Typography>
                                {gig.address && <div className="flex flex-col">
                                    <CardDataFieldLabel>Address: </CardDataFieldLabel><Typography variant="body" component="p">{gig.address}</Typography>
                                </div>}
                            </div>
                            <div className="space-y-4">
                                <div>
                                    <div className="flex flex-row gap-2">
                                        <CardDataFieldLabel>Start date: </CardDataFieldLabel><Typography variant="body" component="p">
                                            <div className="flex items-center">
                                                {formatLocationDateTimeToString(gig.startDate, gig.ianaId)}
                                                <Tooltip className="ml-2" tooltipText="" dateDisplay={<DateDisplayLocale date={gig.startDate} />}></Tooltip>
                                            </div>
                                        </Typography>
                                    </div>
                                    <div className="flex flex-row gap-2">
                                        <CardDataFieldLabel>Duration: </CardDataFieldLabel><Typography variant="body" component="p">{gig.expectedDurationLabel}</Typography>
                                    </div>
                                </div>
                                {gig.description && (
                                    <div className="flex flex-col">
                                        <CardDataFieldLabel>Program Objectives: </CardDataFieldLabel><Typography variant="body" component="p" className="whitespace-pre-wrap">{gig.description}</Typography>
                                    </div>
                                )}

                                {gig.delegateInformation && (
                                    <div className="flex flex-col">
                                        <CardDataFieldLabel>Delegate Information: </CardDataFieldLabel>
                                        <Typography variant="body" component="p" className="whitespace-pre-wrap">
                                            {gig.delegateInformation}
                                        </Typography>
                                    </div>

                                )}
                                {hasCustomRequirements && (
                                    <div className="flex flex-col">
                                        <CardDataFieldLabel>Event Customisation Requirements: </CardDataFieldLabel>
                                        <ol className="list-disc ml-4 gap-2">
                                            {gig.isDesignFeeTypeRequired && (
                                                <li>
                                                    <div className="flex flex-row gap-2">
                                                        <CardDataFieldLabel textClass="font-normal">Customisation of content and/or addition of new content</CardDataFieldLabel>
                                                        <Typography variant="body" component="p">{`(${gig.designFeeTypeHours} hours)`}</Typography>
                                                    </div>
                                                </li>
                                            )}
                                            {gig.isConsultancyFeeTypeRequired && (
                                                <li>
                                                    <div className="flex flex-row gap-2">
                                                        <CardDataFieldLabel textClass="font-normal">Consultancy time required with client</CardDataFieldLabel>
                                                        <Typography variant="body" component="p">{`(${gig.consultancyFeeTypeHours} hours)`}</Typography>
                                                    </div>
                                                </li>
                                            )}
                                            {gig.isPrepAndEvalFeeTypeRequired && (
                                                <li>
                                                    <div className="flex flex-row gap-2">
                                                        <CardDataFieldLabel textClass="font-normal">Additional prep & evaluation time required</CardDataFieldLabel>
                                                        <Typography variant="body" component="p">{`(${gig.prepAndEvalFeeTypeHours} hours)`}</Typography>
                                                    </div>
                                                </li>
                                            )}
                                        </ol>
                                    </div>
                                )}
                            </div>

                            {/* {gig.files && gig.files.length > 0 && (
                                <GigFilesAccordion files={gig.files} />
                            )} */}

                            {clientId && (
                                <>
                                    <TitleUnderline fullWidth />
                                    <div className="space-y-2">
                                        <Typography variant="body" component="p" className="underline">Additional information (client only):</Typography>
                                        <div>
                                            <div className="flex flex-row gap-2">
                                                <CardDataFieldLabel>Client History: </CardDataFieldLabel><Typography variant="body" component="p">{gig.hasClientHistory ? "Yes" : "No"}</Typography>
                                            </div>
                                            {gig.opportunityLink && <div className="flex flex-row gap-2"><CardDataFieldLabel>Opportunity Link: </CardDataFieldLabel><Typography variant="body" component="p">{gig.opportunityLink}</Typography></div>}
                                        </div>
                                        {gig.notes && <div className="flex flex-col">
                                            <CardDataFieldLabel>Notes: </CardDataFieldLabel><Typography variant="body" component="p">{gig.notes}</Typography>
                                        </div>}
                                    </div>
                                </>
                            )}
                        </div>

                        {organizationConfig.isReportsEnabled && (
                            <div className="mt-6">
                                <ReportButton
                                    type="Gig"
                                    entityId={gigId}
                                    className="text-black hover:text-primary"
                                />
                            </div>
                        )}

                        <AuditDataList referenceNumber={gig.referenceNumber} createdBy={gig.createdBy} lastUpdatedBy={gig.lastUpdatedBy} className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-4" />

                    </div>
                    <div className="md:ml-auto md:self-start md:shrink-0 space-y-8">
                        <div className="child:p-4 child:border-surface border border-surface rounded-lg">
                            <CardDataField className="border-b" label="Industry">{gig.industry?.name}</CardDataField>
                            <CardDataField className="border-b" label="Delivery Format">{deliveryTypeOptions[gig.deliveryTypeId].label}</CardDataField>
                            <CardDataField className="border-b" label="No. of participants">{gig.expectedNumberParticipants}</CardDataField>
                            <CardDataField className="border-b" label="Executive Audience">{gig.isAudienceExecutive ? "Yes" : "No"}</CardDataField>
                            <CardDataField className="border-b" label="Language">{gig.language?.name}</CardDataField>
                        </div>
                        {isTalent && <TalentInviteActions gig={gig} />}
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default FindAGigDetailsPage;
