import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm/GigFormValues";
import { formClassNames } from "./classNames";
import { deliveryTypeOptions } from "../../models/app/gig";
import FormDropdown from "../FormDropdown";
import { useEffect } from "react";
import useAccessToken from "../../auth/useAccessToken";
import FormTextAreaInput from "../FormTextAreaInput";
import { LocationSearchDropdown } from "../LocationSearchDropdown";
import { LocalitySearchDropdown } from "../LocalitySearchDropdown";

export type CreateGigFormStep5Fields = Pick<GigFormValues, "deliveryTypeId" | "locationId" | "localityId" | "ianaId" | "address">;

export type CreateGigFormStep5Props = {
    onSubmit: (value: CreateGigFormStep5Fields) => void
    onBackClick: (value: CreateGigFormStep5Fields) => void
    defaultValues: CreateGigFormStep5Fields
}

const CreateGigFormStep5 = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep5Props) => {
    const accessToken = useAccessToken();

    const validationSchema: yup.SchemaOf<CreateGigFormStep5Fields> = yup.object({
        deliveryTypeId: buildGigFormValidationSchemaFields.deliveryTypeId,
        locationId: buildGigFormValidationSchemaFields.locationId,
        localityId: buildGigFormValidationSchemaFields.localityId,
        ianaId: buildGigFormValidationSchemaFields.ianaId,
        address: buildGigFormValidationSchemaFields.address
    });

    const methods = useForm<CreateGigFormStep5Fields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    // Initialize form with default values
    useEffect(() => {
        if (defaultValues) {
            methods.reset(defaultValues);
        }
    }, [defaultValues, methods.reset]);
    
    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="Location"
                description="Specify the delivery type of the event and where the client is located."
                content={
                    <span className="space-y-6">
                        <Controller
                            name="deliveryTypeId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="What is the required delivery format for this event?"
                                    options={deliveryTypeOptions}
                                    error={methods.formState.errors.deliveryTypeId}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        <Controller
                            name="locationId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <LocationSearchDropdown
                                    required
                                    label="What country is the client located in?"
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        // Clear locality when country changes
                                        if (newValue !== value) {
                                            methods.setValue("localityId", "");
                                        }
                                    }}
                                    error={methods.formState.errors.locationId}
                                    accessToken={accessToken}
                                    isClearable
                                />
                            )}
                        />
                        {methods.watch("locationId") && (
                            <Controller
                                name="localityId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <LocalitySearchDropdown
                                        required
                                        label="City"
                                        locationId={methods.watch("locationId")}
                                        value={value}
                                        onChange={(value, option) => {
                                            onChange(value);
                                            methods.setValue("ianaId", option?.ianaId);  // Set the ianaId
                                        }}
                                        error={methods.formState.errors.localityId}
                                        accessToken={accessToken}
                                        isClearable
                                    />
                                )}
                            />
                        )}
                        <FormTextAreaInput
                            required={false}
                            id="address"
                            label="Address"
                            placeholder="Please enter the exact address of the event, if available"
                            error={methods.formState.errors.address}
                            tooltip="Please enter the exact address of the event, if available"
                            register={methods.register("address")}
                        />
                    </span>
                }
                iconName="MapPin"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep5;