import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm/GigFormValues";
import { formClassNames } from "./classNames";
import FormTextAreaInput from "../FormTextAreaInput";
import GigTaggedTypeInput from "../GigTaggedTypeInput";
import { clientEditGigTooltips } from "../../tooltipsContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

export type CreateGigFormStep7Fields = Pick<GigFormValues, "notes" | "gigTaggedUsers">;

export type CreateGigFormStep7Props = {
    onSubmit: (value: CreateGigFormStep7Fields) => void
    onBackClick: (value: CreateGigFormStep7Fields) => void
    defaultValues: CreateGigFormStep7Fields
}

const CreateGigFormStep7 = ({
    onSubmit,
    onBackClick,
    defaultValues
}: CreateGigFormStep7Props) => {
    const { gigTerminology } = useOrganizationContext();

    const validationSchema = yup.object({
        notes: buildGigFormValidationSchemaFields.notes,
        gigTaggedUsers: buildGigFormValidationSchemaFields.gigTaggedUsers,
    });

    const methods = useForm<CreateGigFormStep7Fields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    const { append, remove } = useFieldArray<CreateGigFormStep7Fields, "gigTaggedUsers">({
        control: methods.control,
        name: "gigTaggedUsers",
        keyName: "id",
    });
    
    const { gigTaggedUsers } = methods.watch();
        
    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="Notes"
                description="Please leave any notes which may aid the resource team in sourcing the right AC for this client and tag any other users that you would like updated on this request."
                content={
                    <>
                        <FormTextAreaInput
                            id="create-gig-form-notes"
                            label="Notes"
                            error={methods.formState.errors.notes}
                            defaultValue={defaultValues.notes || undefined}
                            placeholder="Add any relevant notes"
                            rows={7}
                            register={methods.register("notes")} />
                        <GigTaggedTypeInput
                            addTagged={append}
                            removeTagged={remove}
                            isMulti={true}
                            gigTaggedUsers={gigTaggedUsers || []}
                            error={methods.formState.errors.gigTaggedUsers}
                            showError={methods.formState.submitCount > 0}
                            hideDropdown={false}
                            tooltip={clientEditGigTooltips.gigTagged(gigTerminology)} />
                    </>
                }
                iconName="Note"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep7;