import { useEffect, useState } from "react";
import SearchableCheckBoxGroup from "../../components/SearchableCheckBoxGroup";
import useAccessToken from "../../auth/useAccessToken";
import { FormDropdownOption } from "../../components/FormDropdown";
import { fetchLocalities } from "../../api/locations";
import { useFindAGigContext } from "../FindAGig/findAGigContext";

export type SearchableLocalitiesCheckBoxGroupProps = {
    selectedLocationId: string;
};

const SearchableLocalitiesCheckBoxGroup = ({
    selectedLocationId
}: SearchableLocalitiesCheckBoxGroupProps) => {
    const accessToken = useAccessToken();
    const { localityIds, setLocalityIds } = useFindAGigContext();
    const [isLoadingLocalityOptions, setIsLoadingLocalityOptions] = useState(false);

    const fetchLocalityOptions = async (query: string, accessToken: string): Promise<FormDropdownOption<string>[]> => {
        if (!selectedLocationId) return [];
        
        try {
            const response = await fetchLocalities(
                selectedLocationId,
                accessToken,
                query,
                0,
                50
            );

            return response.items.map(locality => ({
                label: locality.region 
                    ? `${locality.locality} ( ${locality.region})`
                    : locality.locality,
                value: locality.localityId
            }));
        } catch (error) {
            console.error("Error fetching localities:", error);
            return [];
        }
    };

    useEffect(function setInitialLocalityOptions() {
        if (!accessToken || !selectedLocationId) return;

        const loadInitialLocalities = async () => {
            setIsLoadingLocalityOptions(true);
            try {
                await fetchLocalityOptions("", accessToken);
            } finally {
                setIsLoadingLocalityOptions(false);
            }
        };

        loadInitialLocalities();
    }, [accessToken, selectedLocationId]);
    
    return (
        <SearchableCheckBoxGroup
            selectedOptionIds={localityIds}
            onChange={setLocalityIds}
            fetchOptions={fetchLocalityOptions}
            searchPlaceholder="Search by city"
            disabled={isLoadingLocalityOptions}
        />
    );
};

export default SearchableLocalitiesCheckBoxGroup;