import gigApiFetcher from "./common/fetching";
import { TaggedDto } from "../models/app/taggedUser";

export const fetchTaggedUsers = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100
): Promise<TaggedDto[]> => {
    const response = await gigApiFetcher<never,[]>({
        url: `api/gigged-clients/users?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response; // Ensure response.content is TaggedDto[]
};

export const fetchTaggedOptions = async (query: string, accessToken: string | null, pageIndex: number) => {
    const TaggedResponse = await fetchTaggedUsers(query, accessToken, pageIndex);

    return TaggedResponse.map(tagged => ({
        value: tagged.userId,
        label: tagged.userName,
    }));
};


export const fetchTaggedCount = async (accessToken: string | null) => {
    const response = await gigApiFetcher<never, number>({
        url: "api/gigged-clients/users?pageIndex=0&pageSize=100",
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });
    return response; // Extract count from response.content
};