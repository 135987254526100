import { useState } from "react";
import { FieldError } from "react-hook-form";
import { GroupBase, StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";

import FormInputLabel from "../FormInputLabel";
import FormInputMessage from "../FormInputError";
import FormSearchDropdownControl from "./FormSearchDropdownControl";
import FormSearchDropdownOption from "./FormSearchDropdownOption";
import FormSearchDropdownDropdownIndicator from "./FormSearchDropdownDropdownIndicator";
import FormSearchDropdownMenu from "./FormSearchDropdownMenu";
import FormSearchDropdownClearIndicator from "./FormSearchDropdownClearIndicator";
import FormSearchDropdownMenuList from "./FormSearchDropdownMenuList";

// Following module declaration is required to allow adding a custom prop 
// to the AsyncSelect component.
// The custom prop is required because it is the only way for us to pass
// custom props to any of its child components e.g. Control.
// The custom component also must be defined outside the render method,
// so this is why we also cannot just pass the props directly to the component.
// Ref: https://github.com/JedWatson/react-select/issues/4804
// Ref: https://github.com/JedWatson/react-select/issues/2810#issuecomment-569117980
declare module "react-select/dist/declarations/src/Select" {
    export interface Props<
        Option,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        IsMulti extends boolean,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Group extends GroupBase<Option>
        > {
        error?: FieldError;
    }
}

const customStyles: StylesConfig = {
    valueContainer: (provided) => ({
        ...provided,
        padding: "0px",
        whiteSpace: "nowrap"
    })
};

export type FormSearchDropdownOption = {
    value: string,
    label: string,
    ianaId?: string
}

export type LoadOptionsCallback = (options: FormSearchDropdownOption[]) => void;

export type FormSearchDropdownProps = {
    label: string
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    error?: FieldError
    required?: boolean
    loadOptions: (inputValue: string, callback: LoadOptionsCallback) => void
    isClearable?: boolean
    defaultOptions?: FormSearchDropdownOption[]
    disabled?: boolean
    disabledOptionIds?: string[]
    tooltip?: string
    fetchMore?: () => void
    loading?: boolean
    isMulti?: boolean
    selectedOption?: FormSearchDropdownOption | null
}

const FormSearchDropdown = ({
    label,
    value,
    onChange,
    error,
    required,
    loadOptions,
    isClearable,
    defaultOptions = [],
    disabled,
    disabledOptionIds = [],
    tooltip,
    fetchMore,
    loading,
    isMulti,
    selectedOption
}: FormSearchDropdownProps) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const handleChange = (newValue: unknown) => {
        const option = newValue as (FormSearchDropdownOption | null);
        onChange(option?.value || "", option);
    };

    return (
        <div>
            <FormInputLabel required={required} tooltipText={tooltip}>{label}</FormInputLabel>
            <AsyncSelect
                value={value ? selectedOption : null}
                styles={customStyles}
                isLoading={loading}
                isMulti={isMulti}
                isDisabled={disabled}
                placeholder="Type or select an option"
                loadOptions={loadOptions}
                defaultOptions={defaultOptions}
                isClearable={isClearable}
                onChange={handleChange}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                menuIsOpen={menuIsOpen}
                error={error}
                isOptionDisabled={option => disabledOptionIds.includes((option as FormSearchDropdownOption).value)}
                components={{
                    Control: FormSearchDropdownControl,
                    Option: FormSearchDropdownOption,
                    DropdownIndicator: FormSearchDropdownDropdownIndicator,
                    IndicatorSeparator: () => (null),
                    Menu: FormSearchDropdownMenu,
                    MenuList: FormSearchDropdownMenuList,
                    ClearIndicator: FormSearchDropdownClearIndicator,
                }}
                onMenuScrollToBottom={() => fetchMore && fetchMore()}
            />
            <FormInputMessage error={error} />
        </div>
    );
};

export default FormSearchDropdown;
