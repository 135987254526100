import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Copy } from "phosphor-react";
import { CreateGigDto, GigDetails, gigStatuses } from "../models/app/gig";
import Tooltip from "./Tooltip";
import DialogBox, { useDialogBoxState } from "./DialogBox";
import { dialogWidths } from "./DialogBox/DialogBox";
import Button from "./Button";
import { useGigs } from "../api/gigs";
import { useAppPaths } from "../Routes";
import ErrorList from "./ErrorList";
import { successToast } from "../toast";
import { mapFromApiGigDetailsToCreateGig } from "../models/mappers/gig";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import Typography from "./Typography";
import { formatDateTime } from "../utils/dateFormatters";

export type GigCloneButtonProps = {
  gig: GigDetails;
}

const GigCloneButton = ({ 
    gig 
}: GigCloneButtonProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const { gigTerminology, gigTerminologyPlural } = useOrganizationContext();
    const appPaths = useAppPaths();
    const { createGig, isCreatingGig, isLoading } = useGigs();
    const navigate = useNavigate();

    const cloneDialogBoxState = useDialogBoxState();

    const currentDateTime = formatDateTime(new Date());

    const handleCloneGig = async (values: CreateGigDto) => {
        const response = await createGig({
            gigStatusId: gigStatuses.posted,
            startDate: values.startDate,
            title: values.title + " (clone - " + currentDateTime + ")",
            hasClientHistory: values.hasClientHistory,
            industryId: values.industryId,
            opportunityLink: values.opportunityLink || "",
            isAudienceExecutive: values.isAudienceExecutive,
            languageId: values.languageId,
            delegateInformation: values.delegateInformation || "",
            isDesignFeeTypeRequired: values.isDesignFeeTypeRequired,
            designFeeTypeHours: values.isDesignFeeTypeRequired ? values.designFeeTypeHours : undefined,
            isConsultancyFeeTypeRequired: values.isConsultancyFeeTypeRequired,
            consultancyFeeTypeHours: values.isConsultancyFeeTypeRequired ? values.consultancyFeeTypeHours : undefined,
            isPrepAndEvalFeeTypeRequired: values.isPrepAndEvalFeeTypeRequired,
            prepAndEvalFeeTypeHours: values.isPrepAndEvalFeeTypeRequired ? values.prepAndEvalFeeTypeHours : undefined,
            expectedNumberParticipants: values.expectedNumberParticipants,
            description: values.description || "",
            deliveryTypeId: values.deliveryTypeId,
            locationId: values.locationId,
            localityId: values.localityId,
            notes: values.notes || "",
            expectedDurationId: values.expectedDurationId,
            skillIds: values.skillIds,
            gigTaggedUserIds: values.gigTaggedUserIds
        });

        if (response.success) {
            cloneDialogBoxState.close();
            navigate(appPaths.gigs.myGigs("posted"));
            successToast(`${gigTerminology} duplicated successfully!`);
        } else {
            setErrors(response.error.userMessages);
        }

        return response;
    };

    return (
        <>
            <Tooltip
                tooltipText={`Click to create a duplicate of this ${gigTerminology.toLowerCase()}. The new ${gigTerminology.toLowerCase()} will appear on your 'Posted ${gigTerminologyPlural}' page.`}
            >
                <Copy size={32} className="cursor-pointer" onClick={cloneDialogBoxState.open} />
            </Tooltip>

            <DialogBox
                {...cloneDialogBoxState}
                title={`Duplicate ${gigTerminology.toLowerCase()}`}
                maxWidth={dialogWidths.medium}
            >
                <Typography variant="body" component="p">
                Are you sure you wish to duplicate this {gigTerminology.toLowerCase()}? The new {gigTerminology.toLowerCase()} will automatically be posted, and will appear on your 'Posted {gigTerminologyPlural}' page where you can edit the details if required.
                </Typography>
                <div className="flex flex-col-reverse md:flex-row gap-2 md:gap-0 md:space-x-2 justify-between mb-2">
                    <Button className="block w-full md:w-fit" variant="tertiary" onClick={cloneDialogBoxState.close}>Back</Button>
                    <Button className="block w-full md:w-fit" loading={isCreatingGig || isLoading} onClick={() => handleCloneGig(mapFromApiGigDetailsToCreateGig(gig))}>
                        Confirm clone
                    </Button>
                </div>
                <ErrorList errors={errors} />
            </DialogBox>
        </>
    );
};

export default GigCloneButton;