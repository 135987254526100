import { useState } from "react";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import useBeforeUnload from "../../hooks/useWarnBeforeUnload";
import { CreateGigDto } from "../../models/app/gig";
import { GigFormValues } from "../GigForm";
import TabStepper from "../TabStepper";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8Summary from "./Step8Summary";
import { getLocalEquivalentDateTimeString } from "../../utils/dateFormatters";

export type GigFormProps = {
    onSaveAsDraft: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>,
    onSaveAndPost: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>,
}

const CreateGigForm = ({
    onSaveAsDraft,
    onSaveAndPost,
}: GigFormProps) => {
    useBeforeUnload();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [gigFormData, setGigFormData] = useState<GigFormValues>({
        title: "",
        hasClientHistory: "",
        industryId: "",
        opportunityLink: "",
        isAudienceExecutive: "",
        languageId: "",
        delegateInformation: "",
        skills: [],
        gigTaggedUsers: [],
        startDate: null,
        isDesignFeeTypeRequired: "",
        isConsultancyFeeTypeRequired: "",
        isPrepAndEvalFeeTypeRequired: "",
        designFeeTypeHours: undefined,
        consultancyFeeTypeHours: undefined,
        prepAndEvalFeeTypeHours: undefined,
        expectedDurationId: -1,
        expectedNumberParticipants: 0,
        description: "",
        deliveryTypeId: -1,
        locationId: "",
        localityId: "",
        gigStatusId: 1,
        ianaId: "",
        address: ""
    });

    const updateAction = (data: Partial<GigFormValues>) => {
        setGigFormData(oldValue => ({
            ...oldValue,
            ...data
        }));
    };

    const handleBackClick = (data: Partial<GigFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value - 1);
        window.scrollTo(0, 0);
    };

    const handleStepSubmit = (data: Partial<GigFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value + 1);
        window.scrollTo(0, 0);
    };

    const handleSubmit = async (data: GigFormValues, callback: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>) => {
        setIsSubmitting(true);
        updateAction(data);
        
        if (data.startDate === null) throw Error("Expected start date not to be null.");

        const response = await callback({
            ...data,
            startDate: getLocalEquivalentDateTimeString(data.startDate, data.ianaId ?? ""),
            skillIds: data.skills.map(skill => skill.id),
            gigTaggedUserIds: data.gigTaggedUsers ? data.gigTaggedUsers.map(tagged => tagged.userId) : [],
            hasClientHistory: JSON.parse(data.hasClientHistory),
            isDesignFeeTypeRequired: JSON.parse(data.isDesignFeeTypeRequired),
            isConsultancyFeeTypeRequired: JSON.parse(data.isConsultancyFeeTypeRequired),
            isPrepAndEvalFeeTypeRequired: JSON.parse(data.isPrepAndEvalFeeTypeRequired),
            isAudienceExecutive: JSON.parse(data.isAudienceExecutive),
            description: data.description || null,
            delegateInformation: data.delegateInformation || null,
            opportunityLink: data.opportunityLink || null,
            notes: data.notes || null,
            address: data.address || null
        });
        setIsSubmitting(false);

        if (!response.success) {
            setSubmissionError(response.error);
        }
    };

    const handleSaveAsDraft = async (data: GigFormValues) => {
        await handleSubmit(data, onSaveAsDraft);
    };

    const handleSaveAndPost = async (data: GigFormValues) => {
        await handleSubmit(data, onSaveAndPost);
    };

    const pages = [
        {
            title: "Client",
            content: <Step1 onSubmit={handleStepSubmit} defaultValues={gigFormData} />
        },
        {
            title: "Information",
            content: <Step2 onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Customisation",
            content: <Step3 onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Objectives",
            content: <Step4 onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Location",
            content: <Step5 onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Date & Time",
            content: <Step6 onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Notes",
            content: <Step7 onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Summary",
            content: <Step8Summary onSaveAsDraft={handleSaveAsDraft} onSaveAndPost={handleSaveAndPost} isSubmitting={isSubmitting} defaultValues={gigFormData} submissionError={submissionError} />
        }
    ];

    return (
        <>
            <TabStepper
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                pages={pages}
            />
        </>
    );
};

export default CreateGigForm;