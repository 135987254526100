import { useCallback, useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { FormSearchDropdown } from "./FormSearchDropdown";
import { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";
import { fetchLocalities, fetchLocalityById } from "../api/locations";
import { LocalityDto } from "../models/app/location";

type LocalitySearchDropdownProps = {
    locationId: string
    value: string
    onChange: (localityId: string, option: FormSearchDropdownOption | null) => void
    error?: FieldError
    label?: string
    required?: boolean
    disabled?: boolean
    tooltip?: string
    accessToken: string | null
    isClearable?: boolean
}

export const LocalitySearchDropdown = ({
    locationId,
    value,
    onChange,
    error,
    label = "City",
    required,
    disabled,
    tooltip,
    accessToken,
    isClearable
}: LocalitySearchDropdownProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState<FormSearchDropdownOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<FormSearchDropdownOption | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const pageSize = 50;

    // Only fetch the initial value when the component mounts or when locationId changes
    useEffect(() => {
        const loadInitialSelectedLocality = async () => {
            // Only fetch if we have a value and it's not already in our options
            if (value && !defaultOptions.some(opt => opt.value === value)) {
                setIsLoading(true);
                const locality = await fetchLocalityById(value, accessToken);
                if (locality) {
                    const option = {
                        value: locality.localityId,
                        label: `${locality.locality}${locality.region ? ` (${locality.region})` : ""}`,
                        ianaId: locality.ianaId,
                    };
                    setSelectedOption(option);
                    setDefaultOptions(prev => [option, ...prev]);
                }
                setIsLoading(false);
            }
        };

        loadInitialSelectedLocality();
    }, [locationId, value, accessToken]);

    // Load initial localities for the dropdown
    useEffect(() => {
        const loadInitialOptions = async () => {
            if (!locationId || !accessToken) {
                setDefaultOptions([]);
                setSelectedOption(null);
                return;
            }

            try {
                setIsLoading(true);
                const response = await fetchLocalities(locationId, accessToken, "", 0, pageSize);
                
                const options = response.items.map((locality: LocalityDto) => ({
                    value: locality.localityId,
                    label: `${locality.locality}${locality.region ? ` (${locality.region})` : ""}`,
                    ianaId: locality.ianaId
                }));

                setDefaultOptions(prev => {
                    const combined = [...prev, ...options];
                    return Array.from(new Map(combined.map(item => [item.value, item])).values());
                });
                setCurrentPage(0);
                setHasMore(response.hasNextPage);
            } catch (error) {
                console.error("Error loading initial localities:", error);
                setDefaultOptions([]);
                setSelectedOption(null);
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialOptions();
    }, [locationId, accessToken]);

    const loadLocalityOptions = useCallback(async (
        inputValue: string,
        callback: LoadOptionsCallback
    ) => {
        if (!locationId || !accessToken) {
            callback([]);
            return;
        }

        try {
            setIsLoading(true);
            const response = await fetchLocalities(locationId, accessToken, inputValue, 0, pageSize);
            
            const options = response.items.map((locality: LocalityDto) => ({
                value: locality.localityId,
                label: `${locality.locality}${locality.region ? ` (${locality.region})` : ""}`,
                ianaId: locality.ianaId
            }));

            callback(options);
            setCurrentPage(0);
            setHasMore(response.hasNextPage);
        } catch (error) {
            console.error("Error fetching localities:", error);
            callback([]);
        } finally {
            setIsLoading(false);
        }
    }, [locationId, accessToken]);

    const handleFetchMore = useCallback(async () => {
        if (!hasMore || !locationId || isLoading || !accessToken) return;

        try {
            setIsLoading(true);
            const nextPage = currentPage + 1;
            const response = await fetchLocalities(
                locationId,
                accessToken,
                "", // Empty search term for initial load
                nextPage,
                pageSize
            );

            const newOptions = response.items.map((locality: LocalityDto) => ({
                value: locality.localityId,
                label: `${locality.locality}${locality.region ? ` (${locality.region})` : ""}`
            }));

            setDefaultOptions(prev => [...prev, ...newOptions]);
            setCurrentPage(nextPage);
            setHasMore(response.hasNextPage);
        } catch (error) {
            console.error("Error fetching more localities:", error);
        } finally {
            setIsLoading(false);
        }
    }, [locationId, accessToken, currentPage, hasMore, isLoading]);

    return (
        <FormSearchDropdown
            label={label}
            value={value}
            onChange={onChange}
            error={error}
            required={required}
            disabled={disabled || !locationId}
            tooltip={tooltip}
            loadOptions={loadLocalityOptions}
            isClearable={isClearable}
            loading={isLoading}
            defaultOptions={defaultOptions}
            fetchMore={hasMore ? handleFetchMore : undefined}
            selectedOption={selectedOption}
        />
    );
};