import { GigMatch } from "../models/app/gigMatch";
import Card from "./Card";
import { CardDataField } from "./Card";
import CardDataFieldLabel from "./Card/CardDataFieldLabel";
import InviteToGigButton from "./InviteToGigButton";
import UserAvatar from "./UserAvatar";
import clsx from "clsx";
import BadgeList from "./BadgeList";
import Button from "./Button";
import { Link } from "react-router-dom";
import { useAppPaths } from "../Routes";
import Typography from "./Typography";
import GigMatchedItem from "./GigMatchedItem";
import { Chip } from "@mui/material";

export type GigTalentMatchCardProps = {
    gigId: string
    match: GigMatch
}

const GigTalentMatchCard = ({
    gigId,
    match
}: GigTalentMatchCardProps) => {
    const matchValue = `${Math.round(match.score)}%`;
    const appPaths = useAppPaths();

    const matchDisplayName = `${match?.talentFirstName} ${match?.talentLastName}`;
    const matchCompanyName = match.talentTradingName;
    const displayBadgeSection = match.badges.length > 0;

    return (
        <Card className={"max-w-full space-y-6 md:space-y-0 md:space-x-6 md:flex md:items-center flex-row"}>
            <div className="md:w-[230px] flex flex-col items-center space-y-2">
                <UserAvatar name={matchDisplayName} userImageUrl={match.talentProfileImageThumbnailUrl} />
                {!matchCompanyName && <Typography component="p" variant="body" className="text-center">{matchCompanyName}</Typography>}
                <Chip label={match.isAvailable ? "Free" : "Busy"} color={match.isAvailable ? "success" : "warning"} variant="filled" size="medium"/>
                {match.distance != null && (
                    <p className="flex items-center font-bold">
                        <img
                            src="/location-pin.png"
                            alt="Distance"
                        />
                        {match.distance} Km
                    </p>
                )}
                <Link to={appPaths.talents.findTalentDetails(match.talentId)} target="_blank"><Button className="w-fit" variant="tertiary">View profile</Button></Link>
            </div>
            <div className={clsx("grow space-y-3 text-left")}>
                <CardDataField label="Match level">
                    {matchValue}
                </CardDataField>
                <div>
                    <CardDataFieldLabel className="text-left">Relevant Insights workshop types</CardDataFieldLabel>
                    <div className="mt-2 space-y-2 md:max-w-xl text-left">
                        {match.matchedSkills.map(skill => (
                            <GigMatchedItem
                                key={skill.skillName}
                                itemType="skill"
                                itemValue={skill.skillName}
                                {...skill}
                            />
                        ))}
                    </div>
                </div>
                <div>
                    <CardDataFieldLabel className="text-left">Relevant delivery type</CardDataFieldLabel>
                    <div className="mt-2 space-y-2 md:max-w-xl text-left">
                        {match.matchedDeliveryTypes && match.matchedDeliveryTypes.length > 0 ? (
                            match.matchedDeliveryTypes.map(deliveryType => (
                                <GigMatchedItem
                                    key={deliveryType.deliveryType}
                                    itemType="deliveryType"
                                    itemValue={deliveryType.deliveryType}
                                    {...deliveryType}
                                />
                            ))
                        ) : (
                            <p className="text-gray-500">No relevant delivery type found.</p>
                        )}
                    </div>
                </div>
                <div>
                    <CardDataFieldLabel className="text-left">Relevant industry experience</CardDataFieldLabel>
                    <div className="mt-2 space-y-2 md:max-w-xl text-left">
                        {match.matchedIndustrialExperiences && match.matchedIndustrialExperiences.length > 0 ? (
                            match.matchedIndustrialExperiences.map(industrialExperience => (
                                <GigMatchedItem
                                    key={industrialExperience.industry}
                                    itemType="deliveryType"
                                    itemValue={industrialExperience.industry}
                                    {...industrialExperience}
                                />
                            ))
                        ) : (
                            <p className="text-gray-500">No relevant industry found.</p>
                        )}
                    </div>
                </div>
                <div>
                    <CardDataFieldLabel className="text-left">Relevant language</CardDataFieldLabel>
                    <div className="mt-2 space-y-2 md:max-w-xl text-left">
                        {match.matchedLanguages && match.matchedLanguages.length > 0 ? (
                            match.matchedLanguages.map(language => (
                                <GigMatchedItem
                                    key={language.language}
                                    itemType="language"
                                    {...language}
                                />
                            ))
                        ) : (
                            <p className="text-gray-500">No relevant language found.</p>
                        )}
                    </div>
                </div>

                <div>
                    <CardDataFieldLabel className="text-left">Relevant location</CardDataFieldLabel>
                    <div className="mt-2 space-y-2 md:max-w-xl text-left">
                        {match.matchedLocations && match.matchedLocations.length > 0 ? (
                            match.matchedLocations.map(location => (
                                <GigMatchedItem
                                    key={`${location.country}-${location.locality}`}
                                    itemType="location"
                                    {...location}
                                />
                            ))
                        ) : (
                            <p className="text-gray-500">No relevant location found.</p>
                        )}
                    </div>
                </div>
                {displayBadgeSection &&
                    <div>
                        <CardDataFieldLabel className="text-left mb-2">Badges</CardDataFieldLabel>
                        <BadgeList badges={match.badges} keyPrefix={match.talentId.toString()} />
                    </div>
                }

            </div>
            <div className="flex flex-col space-y-4">
                <InviteToGigButton
                    talentId={match.talentId}
                    isInvited={match.isInvited}
                    gigId={gigId}
                    className="block"
                />
            </div>
        </Card>
    );
};

export default GigTalentMatchCard;
